<template>

<div>
     <br>
     <br>
    <el-row>
      <div style="float: left;">
        <el-button
       style="float: left; margin: 7px"
       size="mini"
       type="primary"
       icon="el-icon-plus"
       @click="handleAdd()"
      
     >
       新增費率包
     </el-button>
      </div>
       <div style="float: right;">
        <el-input v-model="packageName" placeholder="方案名稱" size="small" class="filter-item-mid" width="140px" clearable>  
        </el-input>
      <el-button
       style="float: right; margin: 0px"
       size="small"
       type="primary"
       icon="el-icon-search"
       @click="searchPackage()"
      
     >
       查詢
     </el-button>
      </div>
     </el-row>
     
     <br>
     <br>
     <el-row>
     <!-- table -->
     <el-table
       :data="packageList" style="width: 100%;"
       :cell-style="{padding: '0', height: '70px'}"
       @sort-change="tableSortChange"
     >
       <el-table-column align="center" prop="pkName" label="方案名稱" />
       <el-table-column align="center" prop="rateType" label="費率類型" />
       <el-table-column align="center" prop="priceType" label="定價類型" />
       <el-table-column align="center" prop="createTime" label="建立時間" />
       <el-table-column align="center" prop="updateTime" label="修改時間" />
       <el-table-column align="center"  label="操作"  >
       <template slot-scope="scope">
               <el-button  type="success" size="mini" icon="el-icon-edit"  @click="settingPackage(scope.row)"/>
               <el-button  type="danger" size="mini" icon="el-icon-delete"  @click="deletePackage(scope.row)"/>
              </template>
       </el-table-column>
     </el-table>

     <!--分頁組件-->
     <pagination  v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="size" @pagination="searchPackage('2')" :page-sizes="[10]"/>
     </el-row>

     <el-dialog width="500px"  :visible.sync="dialogPackageVisible" @close="dialogOnClose()">
      <span class="filter-label" style="font: 2em sans-serif;"> {{this.isModify==true? "編輯費率包":"新增費率包" }}</span>
      <el-divider content-position="left"></el-divider>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">費率包名稱: </span>
            <el-input v-model="D_Package_Name"  size="small" class="filter-item-long" width="140px" clearable/>  
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">費率類型: </span>
          <el-select v-model="D_Package_Type_2"   size="small" class="filter-item-long" width="140px" >
          <el-option v-for="item in options" :key="item.label" :label="item.value" :value="item.label"/>
         </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">單位價格: </span>
            <el-input v-model="D_Package_Price"  size="small" class="filter-item-long" width="140px" clearable/>  
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">費率說明: </span>
            <el-input v-model="D_Package_Dec"  size="small" class="filter-item-long" width="140px" clearable/>  
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">定價類型: </span>
            <el-input v-model="D_Package_Price_Type"  placeholder="固定費率" size="small" class="filter-item-long" width="140px" disabled/>  
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label" v-show="this.isModify==true">異動原因: </span>
            <el-input v-model="D_Package_Reason"  v-show="this.isModify==true" size="small" class="filter-item-long" width="140px" clearable/>  
          </el-col>

      </el-row>
    <br>
    <br>
      <el-button size="small" @click="updateData()">
        儲存
      </el-button>
      <el-button size="small"  @click="clearData()">
        重設
      </el-button>

  
  </el-dialog>
  </div>

</template>

<script>
import Pagination from '@/components/Pagination';
import { mixins } from '@/views/common/mixins.js';

/* 每隔10min刷新出jwt token*/
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import * as main from "@/utils/main";
import {apiUrl} from "@/utils/main";
const authUrl = main.authUrl();
const refreshTokenUrl = authUrl + "refreshToken";
const refreshTokenInterval = 10*60*1000;     // 10分鐘



export default {
name: 'package',
mixins: [mixins],
components: {
           Pagination
       },
data() {
  return {
    packageName  : undefined,
    packageList  :[],
    pagePackage:0,
    dialogPackageVisible:false,
    serviceId: this.$store.state.currentAccount.serviceInfo.id,
    total:0,
    size:10,
    isModify:false,
    /*---新增刪除頁面參數---*/
    D_Package_Name:undefined,
    D_Package_Type:undefined,
    D_Package_Type_2:undefined,
    D_Package_Price:undefined,
    Package_Unit:undefined,
    D_Package_Dec:undefined,
    D_Package_Price_Type:undefined,  //定價類型
    D_Package_Reason:undefined,
    Package_id:undefined,
    paginationSelectedPage:1,
    listQuery: {
                   type: 'general',
                   station: "",
                   name: undefined,
                   after: undefined,
                   before: undefined,
                   page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                   size: 10
               },
    options: [
    {
    value: '免費',
    label: '1',
    },
    {
    value: '計度',
    label: '2',
    },
   {
    value: '計分',
    label: '3',
   },
 
]

  };
},
watch: {
  paginationSelectedPage: {
    handler: function(newValue) {
      this.pagePackage = newValue - 1
    }
  },
  
},
async created() {
  //this.getStationAndControllerByServiceId();
  this.searchPackage()
},
beforeDestroy() {
  
},
mounted() {
  window.onresize = () => {
    return (() => {
      this.dialogWidth = this.setDialogWidth()
    })()
  }
},
methods: {

  handleAdd(){    //新增頁面
  this.dialogPackageVisible=true
  this.isModify=false
  this.D_Package_Name=undefined
  this.D_Package_Type=undefined
  this.D_Package_Price=undefined
  this.D_Package_Dec=undefined
  },

  optionMapping(str)
  {

 console.log("optionMapping", str);
    if(str==1)
      this.D_Package_Type_2='免費'
    else if(str==2)
      this.D_Package_Type_2='計度'
    else
      this.D_Package_Type_2='計分'

  },
  settingPackage(row){  //修改頁面
    this.isModify=true
    this.dialogPackageVisible=true
    this.D_Package_Name=row.pkName
    this.D_Package_Type=row.rateType
    this.D_Package_Price=row.ratePrice
    this.D_Package_Dec=row.description
    this.Package_Unit=row.rateUnit
    this.Package_id=row.id
    this.optionMapping(this.D_Package_Type)
  },
  searchPackage(){
    if(this.packageName==null)
       this.strURL="/api/v1/packages/query/packages_list?size=10&sort=id,desc&serviceId="+this.serviceId+"&page="+this.pagePackage   
    else
       this.strURL="/api/v1/packages/query/packages_list?size=10&sort=id,desc&serviceId="+this.serviceId+"&name="+this.packageName+"&page="+this.pagePackage 

    console.log("searchPackage", this.strURL);
    this.axios.get(this.strURL).then(
        res => {
        if(res.data.message=='ok')
        {
          this.total=res.data.data.totalElements
          this.packageList=res.data.data.content;      
        }  
        else
        {
          this.$message({ message:'查詢失敗', type: 'warning' })
        }
        }
       );
       console.log("this.strURL", this.packageList);
  },

  
  deletePackage(strID)
  {
    console.log("deletePackage");
    this.strURL="/api/v1/packages/delete/cpc_package"
    console.log("this.strURL", this.strURL);
    this.axios.post(this.strURL, {
      serviceId: this.serviceId,
      //stationId:1, //可以不帶
      id:strID.id
    }).then(response => {
          if(response.data.code==200)
          {
            if(response.data.message =='ok')
            {
            this.$message({ message: '刪除成功', type: 'warning' })
            this.searchPackage()
            } 
            else
              this.$message({ message: response.data.message, type: 'warning' })
          }
          else
             this.$message({ message: response.data.message, type: 'warning' })
  })
  },
  updateData()
  {
    if(this.isModify==true) //修改
    {
      this.strURL ="/api/v1/packages/update/cpc_package"
      console.log("updateData", this.strURL);
      this.axios.post(this.strURL, {
        serviceId:this.serviceId,
        //stationId:1, //可以不帶
        id:this.Package_id,
        pkName:this.D_Package_Name,
        rateType:this.D_Package_Type,  
        ratePrice:this.D_Package_Price,   //hello
        rateUnit:this.Package_Unit,   //kwh  OK
       // priceType:this.D_Package_Price_Type,  //OK
        priceType:1,  //目前固定為1, 固定費率
        description:this.D_Package_Dec,
    })
    .then(response => {
      console.log("response.data.status", response.data.status);
      if(response.data.status == 200){
        this.searchPackage()
        this.$message({ message: response.data.message, type: 'warning' })
      }
      else
        this.$message({ message: response.data.message, type: 'warning' })
  })
  }
  else   //新增
  {
    if(this.D_Package_Type=='1' || this.D_Package_Type=='2')
    {
      this.Package_Unit='kwh'
    }
    else
     this.Package_Unit='min'
    
    console.log("rateType1", this.D_Package_Type_2);
      this.strURL ="/api/v1/packages/create/cpc_package"
      this.axios.post(this.strURL, {
        serviceId:this.serviceId,
        //stationId:1, //可以不帶
        id:this.Package_id,
        pkName:this.D_Package_Name,
        rateType:this.D_Package_Type_2,  
        ratePrice:this.D_Package_Price,   //hello
        rateUnit:this.Package_Unit,   //kwh  OK
        priceType:1,  //目前固定為1, 固定費率
     
    })
    .then(response => {
      console.log("response.data.code", response.data.code);
      if(response.data.code == 200){
        this.searchPackage()
        this.$message({ message: response.data.message, type: 'warning' })
      }
      else
        this.$message({ message: response.data.message, type: 'warning' })
  })
  }
   

  },
  clearData()
  {
    console.log("clearData");
  this.D_Package_Name=undefined
  this.D_Package_Type=undefined
  this.D_Package_Price=undefined
  this.D_Package_Dec=undefined
  this.D_Package_Type_2=undefined
  },

 
  dialogOnClose()
  {
    console.log("dialogOnClose:");
    this.searchSetting()
  }
}

}
</script>

<style lang="scss" scoped>
.checkbox {
zoom:100%;
}
.alert {
zoom:100%;
}


.data-label {
width: 100px; display: inline-block;
}
.underline{
    text-decoration: underline;
    font-size: 15px;
    color: blue
  }
</style>
